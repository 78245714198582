:root {
  --p-purple: #9610ff;
  --bs-transparent: #fff;
  --bs-gray-700: #616161;
  height: 100%;

}

body {
  height: 100%;
}

#root {
  height:100%;
}

.start {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 100vh;
  padding: 1.5rem;
  justify-content: space-between;
}

.container {
  max-width: 600px;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1.5rem;
}

.top-element {
  position: sticky;
  background: #fff;
  top: 0;
  z-index: 1000;
  padding-top: 1.5rem;
}

.content-element {
  flex-grow: 1 !important;
  margin-top: 10px;
}

.bottom-element {
  position: sticky;
  background: #fff;
  bottom: 0;
  z-index: 1000;
  padding-bottom: 1.5rem;
}

.chats {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 100vh;
  padding: 1.5rem;
  margin-bottom: 60px;
}

.cardContainer {
  position: relative;
  height: 75dvh;
  touch-action: none;
}

.swipe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.swipeCard {
  position: absolute;
  width: 100%;
  height: 75dvh;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.swipeCard .person {
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  overflow: hidden;
}

.swipeCard .person-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu {
  position: sticky;
  background: #fff;
  bottom: 0;
  z-index: 1000;
}

a {
  color: var(--p-purple);
  text-decoration: none;
}

.hr {
  color: var(--bs-tertiary-bg);
}

.controls {
  min-height: 50px;
  align-content: center;
  align-items: center;
}

.choice-options {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.choice-options + .choice-options {
  margin-top: 6rem;
}

@media (max-height: 700px) {
  .choice-options + .choice-options {
    margin-top: 3rem;
  }
}

.btn {
  border-radius: 23px;
}

.sign-in .btn-primary,
.sign-in .btn-secondary {
  min-height: inherit;
  min-width: min-content;
  width: 180px;
}

.btn-primary {
  background-color: var(--p-purple);
  border-color: var(--p-purple);
}

.btn-primary:hover {
  background-color: var(--p-purple);
  border-color: var(--p-purple);
}

.btn-secondary {
  background-color: var(--bs-transparent);
  color: var(--p-purple);
  border-color: var(--p-purple);
}

.btn-secondary:hover {
  background-color: var(--bs-transparent);
  color: var(--p-purple);
  border-color: var(--p-purple);
}

.btn-tertiary {
  border: 1px solid #e0e0e0;
}

.btn-delete {
  background-color: #fff;
  font-weight: 500;
}

.btn-verify {
  background-color: var(--p-purple);
  border-color: var(--p-purple);
  color: #fff;
}

.btn-send {
  display: flex;
  border: 1px solid;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  align-items: center;
  justify-content: center;
}

.btn-send:hover {
  display: flex;
  border: 1px solid;
  border-radius: 50%;
  background-color: none;
  border-color: #f4f4f4;
}

.btn.disabled,
.btn:disabled {
  background: #f5f5f5;
  color: #9e9e9e;
  border: #9e9e9e;
}

.welcome {
  background: url("static/images/welcome.png");
  background-size: cover;
  min-height: 100vh;
  color: #fff;
  background-position: center center;
}

.welcome-logo {
  max-width: 220px;
  margin: 0 auto;
  display: block;
  margin-bottom: 1rem;
}

.chat-logo {
  max-height: 34px;
}

.welcome h1 {
  font-size: calc(1.375rem + 2.4vw);
}

.welcome h2 {
  margin-bottom: 6rem;
  font-size: calc(1.325rem + 1.6vw);
}

@media (max-height: 700px) {
  .welcome h2 {
    margin-bottom: 3rem;
    font-size: calc(1.325rem + 1.6vw);
  }
}

.password-reset {
  color: var(--p-purple);
  cursor: pointer;
}

.form-label {
  font-weight: 600;
}

.distance .form-label,
.age-range .form-label {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
}

.form-control,
.form-select {
  background-color: var(--bs-tertiary-bg);
}

.personal-status .form-check {
  margin-bottom: 1rem;
}

.form-check-input {
  margin-right: 1rem;
}

.form-check-input:checked {
  background-color: var(--p-purple);
  border-color: var(--p-purple);
}

.form-control,
.form-select {
  height: 50px;
  border: 0;
}

.input-group.otp,
.input-group.birthday {
  gap: 8px;
}

.input-group.otp > .form-control,
.input-group.birthday > .form-control,
.input-group.birthday > .form-select {
  text-align: center;
  font-weight: 700;
}

.input-group-text {
  border: none;
}

.input-group > .form-control,
.input-group > .form-floating,
.input-group > .form-select {
  border: 0;
}

.alert-danger {
  border: none;
}

h1 {
  margin-bottom: 1.2rem;
}

.task-description {
  margin-bottom: 1.5rem;
}

.task-description-question {
  margin-bottom: 0.5rem;
}

.account-create,
.account-login {
  margin-right: 0.5rem;
}

.account-create-information,
.subscription-contract-details {
  color: var(--bs-gray-700);
}

.email-confirmation-resend {
  font-weight: 600;
}

.icon .bi-envelope {
  font-size: 38px;
}

.progress {
  --bs-progress-bar-bg: var(--p-purple);
  border-radius: 1000px;
}

.progress-bar {
  border-radius: 1000px;
}

.picture-element {
  position: relative;
}

.picture {
  background: var(--bs-transparent);
  height: 20vh;
  border: 1px solid var(--bs-secondary-bg);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.picture-delete {
  position: absolute;
  right: -8px;
  top: -8px;
  height: 20px;
  width: 20px;
  background: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 1000px;
  z-index: 100;
}

.picture-policies {
  border: 1px solid var(--bs-secondary-bg);
  border-radius: 12px;
  padding: 1rem;
  font-size: 0.8rem;
  margin-bottom: 1.5rem;
}

.picture-policies ol {
  padding-left: 1rem;
  list-style-type: disc;
  margin-bottom: 0;
}

.action {
  text-align: center;
  font-weight: 600;
}

.profile-information-group {
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  padding: 0.5rem 0.75rem;
}

.profile-information-group hr {
  margin: 0.5rem 0;
  color: #e0e0e0;
  opacity: 1;
}

.information-group-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.information-group-title {
  font-weight: 600;
  padding: 0.5rem 0;
}

.information-group-content {
  padding: 0.5rem 0;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.information-element {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 23px;
  padding: 0.375rem 0.75rem;
  border: 1px solid #e0e0e0;
}

.information-element-title {
  font-weight: 600;
  display: flex;
}

.element-title {
}

.information-element-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.information-element-list:last-child {
  margin-bottom: 0rem;
}

.questions .information-element-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.element-selection {
  margin-right: 8px;
}

.information-element-selection {
  display: flex;
}

.carousel-item img {
  max-height: 400px;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
  image-orientation: from-image;
}

.profile-name {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.quick-information-element {
  margin-bottom: 0.5rem;
}

.profile-quick-information {
  padding-bottom: 1rem;
}

.profile-group-content {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-bottom: 1rem;
}

.profile-group-title {
  font-weight: 600;
  font-size: 1.1rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  border-top: 1px solid #e0e0e0;
}

.questions .profile-group-title:first-child {
  border-top: 1px solid #e0e0e0;
}

.questions .profile-group-title {
  border-top: none;
}

.quick-navigation-item,
.quick-navigation-item a {
  text-align: center;
  color: #9e9e9e;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quick-navigation-item {
  padding: calc(var(--bs-gutter-x) * 0.5) 0;
}

.quick-navigation-item a {
  padding: 0;
}

.quick-navigation-item-icon {
  position: relative;
}

.chat {
  margin-bottom: 1.5rem;
}

.chat-category {
  font-weight: 600;
  color: var(--p-purple);
  margin-bottom: 1rem;
}

.partner-avatar {
  max-width: min-content;
}

.chat-partner-avatar {
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.chat-partner-avatar img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 50%;
}

.chat-partner-name {
  font-size: 1.1rem;
  font-weight: 600;
}

.chat-date {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
}

.chat-last-message,
.chat-last-message-time {
  font-size: 0.9rem;
}

.chat-last-message-time {
  text-align: right;
}

.chat-notification {
  position: absolute;
  right: 0px;
  bottom: -2px;
  height: 24px;
  width: 24px;
  background: var(--p-purple);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 1000px;
  border: 2px solid #fff;
  font-size: 0.75rem;
}

.chat.block {
  color: #aaa;
}

.chat.block img {
  opacity: 0.6;
}

.chat-action {
  background: #f4f4f4;
  margin-bottom: 1rem;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  gap: 12px;
  border: none;
  border-radius: 0.5rem;
  width: -webkit-fill-available;
}

.chat-action-item {
  flex-direction: row;
  text-align: left;
}

.chat-action-title {
  font-weight: 600;
}

.chat-action-subline {
  font-size: 0.875rem;
}

.chat-input {
  display: flex;
}

.chat-textarea {
  display: flex;
}

.chat-input textarea {
  border: 1px solid #f4f4f4;
  background: #f4f4f4;
  border-radius: 0.5rem;
  padding: 0.75rem;
  resize: none;
  width: -webkit-fill-available;
  outline-color: var(--p-purple);
}

.chat-act {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  gap: 8px;
}

.msg-e-date {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.8rem;
  color: #757575;
}

.msg-n {
  width: -webkit-fill-available;
  display: flex;
  margin-bottom: 1rem;
}

.msg-n-s {
  flex-direction: row-reverse;
}

.msg-n-c-r,
.msg-n-c-s {
  padding: 0.5rem 1rem;
  border-radius: 12px;
}

.msg-n-c-s {
  background: var(--p-purple);
  color: #fff;
}

.msg-n-c-r {
  background-color: #f5f5f5;
}

.message-time {
  font-size: 0.8rem;
  text-align: end;
}

.swipe-view .carousel-item img {
  height: 80vh;
  max-height: unset;
}

.swipe-view .carousel-indicators {
  top: 0;
  margin-bottom: 0;
  margin-top: 1rem;
}

.swipe-information {
  z-index: 300;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 75dvh;
}

.person-information {
  z-index: 100;
  color: #fff;
  padding: 0 1.5rem 0rem 1.5rem;
}

.person {
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 75dvh;
}

.person-image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 1rem);
  height: 100%;
  object-fit: cover;
  z-index: -1;
  border-radius: 0.75rem;
}

.person-images {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80vh;
  z-index: -100;
}

.person-name {
  font-size: 1.6rem;
  font-weight: 600;
}

.actions {
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
}

.action-element {
  height: 36px;
  width: 36px;
  background: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  border: 1.5px solid;
}

.match {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  aspect-ratio: 4 / 5;
}

.match-img {
  border-radius: 12px;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.match-information {
  z-index: 1;
  color: #fff;
  padding: 1rem;
  position: absolute;
  width: -webkit-fill-available;
}

.match .match-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  color: #fff;
}

.match-information {
  z-index: 1;
  color: #fff;
  padding: 1rem;
  position: absolute;
  width: -webkit-fill-available;
}

.match-next {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 1rem);
  height: 75dvh;
  object-fit: cover;
  z-index: -1;
  border-radius: 0.75rem;
  background: #9610ff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  flex-direction: column;
  text-align: center;
  padding: 3rem;
}

.match-profiles {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0 0 0;
}

.match-profiles .chat-partner-avatar {
  margin-left: -0.5rem;
  border: 2px solid #fff;
  border-radius: 50%;
}

.match-request-actions {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.match-notification {
  text-align: center;
  font-weight: 600;
  padding: 1rem 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(0deg, #9610ffc4 0%, #9610ffc4 16%, #0000 48%);
  border-radius: 12px;
}

.person .overlay {
  margin: 0 auto;
  width: 100%;
}

.block .overlay {
  background: #ffffff99;
}

.subscription-option {
  padding: 1.5rem;
  margin-bottom: 1rem;
  background: #fafafa;
  border-radius: 1rem;
}

.subscription-title {
  text-align: center;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.subscription-information {
  border-top: 1px solid #eeeeee;
  padding: 1rem 0 0 1.5rem;
  margin-top: 1rem;
  list-style-image: url("static/images/check.svg");
}

.subscription-information .bi {
  color: var(--p-purple);
  font-weight: bold !important;
}

.subscription-pricing {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 8px;
}

.subscription-price,
.subscription-price-old {
  font-weight: 600;
  font-size: 1.4rem;
}

.subscription-price-old {
  color: #757575;
}

.subscription-price-details {
  text-align: center;
  color: #757575;
}

.subscription-details {
  border-top: 1px solid #eeeeee;
  padding: 1rem 0 0;
  margin-top: 1rem;
}

.subscription-information-element {
  margin-bottom: 0.75rem;
}

.subscription-information-element .bi {
  margin-right: 0.5rem;
}

.slider-container {
  position: relative;
  height: 40px;
}

.slider-track {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #d3d3d3;
  transform: translateY(-50%);
  border-radius: 3px;
}

.slider-range {
  position: absolute;
  top: 50%;
  height: 6px;
  background-color: var(--p-purple);
  transform: translateY(-50%);
  border-radius: 3px;
  z-index: 1;
}

.slider {
  position: absolute;
  width: 100%;
  height: 40px;
  pointer-events: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  z-index: 2;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 2px solid var(--p-purple);
  border-radius: 50%;
  pointer-events: auto;
  cursor: pointer;
  position: relative;
}

.slider::-moz-range-thumb {
  width: 16px;
  height: 16;
}

.modal-content {
  max-width: calc(100% - 3rem);
  margin: 0 auto;
}

.modal-body p {
  margin-bottom: 0;
}
